import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import useStyles from './styles';

function Maps({ coordinates }) {
  const classes = useStyles();

  const MapWithAMarker = withGoogleMap(() => (
    <GoogleMap
      defaultOptions={{
        disableDefaultUI: true,
        mapTypeControl: false,
        gestureHandling: 'greedy',
        scrollwheel: false,
        streetViewControl: false,
        draggable: false,
        keyboardShortcuts: false,
        clickableIcons: false,
      }}
      defaultZoom={16}
      defaultCenter={coordinates}
    >
      <Marker position={coordinates} />
    </GoogleMap>
  ));

  return (
    <div className={classes.root}>
      <MapWithAMarker
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    </div>
  );
}

export default Maps;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import Dialog from '@material-ui/core/Dialog';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { PageHeader } from '../../components/PageHeader';
import Items from './Items';
import Totals from './Totals';
import Modality from './Modality';
import Scheduling from './Scheduling';
import Address from './Address';
import Payment from './Payment';
import Client from './Client';
import Send from './Send';
import { Checkout } from './Checkout';

import { orderClose } from '../../store/modules/order/actions';

import useStyles from './styles';
import { initiatecheckout } from '../../services/application';

const pixelId = process.env.REACT_APP_PIXEL_ID;
const accessToken = process.env.REACT_APP_PIXEL_ACCESS_TOKEN;

export default function Order() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const { modalOrder, order } = useSelector(state => state.order);

  const handleClose = () => {
    dispatch(orderClose());
  };

  useEffect(() => {
    if (modalOrder && pixelId && accessToken) {
      const eventId = 'event_' + Date.now();
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(pixelId, {}, options);
      ReactPixel.track('InitiateCheckout', {
        value: order.subtotal,
        currency: 'BRL',
        num_items: order.items.length,
        content_type: 'product',
        content_ids: order.items.map(item => item.product_id),
        contents: order.items.map(item => ({
          id: item.product_id,
          quantity: item.amount,
          item_price: item.price,
        })),
        eventID: eventId,
      });
      initiatecheckout({
        eventSourceUrl: window.location.origin,
        eventId,
        pixelId,
        accessToken,
        value: order.subtotal,
        currency: 'BRL',
        numItems: order.items.length,
        contentIds: order.items.map(item => item.product_id),
        contents: order.items.map(item => ({
          id: item.product_id,
          quantity: item.amount,
          item_price: item.price,
        })),
      });
    }
  }, [modalOrder, order]);

  return (
    <Dialog fullScreen={fullScreen} open={modalOrder} className={classes.root}>
      <PageHeader handleClose={handleClose} title="Pedido" />
      <div className={classes.content}>
        <Items />
        <Totals />
        <Modality />
        <Scheduling />
        <Address />
        <Payment />
        <Client />
        <Send />
        <Checkout />
      </div>
    </Dialog>
  );
}

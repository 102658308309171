import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ReactPixel from 'react-facebook-pixel';

import useStyles from './styles';
import { orderSuccess } from '../../store/modules/order/actions';
import { purchase } from '../../services/application';

const pixelId = process.env.REACT_APP_PIXEL_ID;
const accessToken = process.env.REACT_APP_PIXEL_ACCESS_TOKEN;

export default function Success() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customer = useSelector(state => {
    const { data } = state;
    const { client } = data;
    const { name } = client;
    return name.split(' ')[0].toLowerCase();
  });

  const { company, client } = useSelector(state => state.data);
  const { modalOrderSuccess, lastOrder: order } = useSelector(
    state => state.order
  );

  useEffect(() => {
    if (modalOrderSuccess && pixelId && accessToken) {
      const eventId = 'event_' + Date.now();
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(pixelId, {}, options);
      ReactPixel.track('Purchase', {
        value: order.subtotal,
        currency: 'BRL',
        num_items: order.items.length,
        content_type: 'product',
        content_ids: order.items.map(item => item.product_id),
        contents: order.items.map(item => ({
          id: item.product_id,
          quantity: item.amount,
          item_price: item.price,
        })),
        eventID: eventId,
      });
      purchase({
        eventSourceUrl: window.location.origin,
        eventId,
        pixelId,
        accessToken,
        name: client ? client.name : '',
        phone: client ? client.phone : '',
        value: order.subtotal,
        currency: 'BRL',
        numItems: order.items.length,
        contentIds: order.items.map(item => item.product_id),
        contents: order.items.map(item => ({
          id: item.product_id,
          quantity: item.amount,
          item_price: item.price,
        })),
      });
    }
  }, [modalOrderSuccess, client, order]);

  const handleClose = () => {
    dispatch(orderSuccess());
  };

  return (
    <Drawer anchor="bottom" open={modalOrderSuccess} onClose={handleClose}>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          Sucesso!
        </Typography>

        <div className={classes.icon}>
          <DoneAllIcon fontSize="large" />
        </div>

        <Typography>
          <strong style={{ textTransform: 'capitalize' }}>{customer}</strong>,
          recebemos o seu pedido!
        </Typography>

        <Typography>
          Se necessário entraremos em contato através do seu número de telefone
          ou WhatsApp
        </Typography>

        <Typography>
          Acompanhe seu pedido na aba <strong>PEDIDOS</strong>
        </Typography>

        {company.entregatempomedio > 0 && order && !order.scheduling && (
          <Typography className={classes.warning}>
            {`Média de ${company.entregatempomedio} minutos para entrega`}
          </Typography>
        )}

        <Button variant="contained" onClick={handleClose}>
          OK entendi
        </Button>
      </div>
    </Drawer>
  );
}
